import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import SEO from "../../components/seo"
import IntroPageWrapper from "../../components/Contents/IntroPageWrapper"
import Transition from "../../components/Animation/Transition"
import { moveCameraToLayer } from "../../components/Animation/CameraAnimation"

export default function Karte({ data, location }) {
  const sendActionToStore = useDispatch()

  // 🚀 Transitions Settings
  const [exit, setExit] = useState(false)
  const defaultInAnimation = location?.state ? location.state.direction : "fade"
  const [AnimationDirection, setAnimationDirection] =
    useState(defaultInAnimation)

  // 🚀🧨 Transitions Redux Triggers
  const TRANSITION_INFO = useSelector(state => state.transition)

  useEffect(() => {
    // * Portal Ref
    sendActionToStore({
      type: "change/markerContainerPortal",
      payload: portalRef,
    })
    // * Activate Locations
    sendActionToStore({
      type: "change/locationsActive",
      payload: true,
    })
    // * Move camera
    moveCameraToLayer(sendActionToStore, "karte")

    // * Unmount
    return () => {
      sendActionToStore({
        type: "change/locationsActive",
        payload: false,
      })
    }
  }, [])

  useEffect(() => {
    if (TRANSITION_INFO.shouldExit && TRANSITION_INFO.direction) {
      setAnimationDirection(TRANSITION_INFO.direction)
      setExit(TRANSITION_INFO.shouldExit)
      sendActionToStore({
        type: `PageChangeTransition/ended`,
      })
    }
  }, [TRANSITION_INFO, sendActionToStore])

  const portalRef = useRef()

  return (
    <>
      <SEO title="Home" />
      <Transition autoIn shouldExit={exit} direction={AnimationDirection}>
        <IntroPageWrapper>
          <div ref={portalRef} id="portal" />
        </IntroPageWrapper>
      </Transition>
    </>
  )
}
// export const query = graphql``
