import React, { useEffect, useState } from "react"
import { useSpring, animated } from "react-spring"

import PropTypes from "prop-types"

const getInAnimation = inDirection => {
  // console.log("inDirection", inDirection)
  switch (inDirection) {
    case "left":
      return {
        fromDirection: {
          opacity: 0,
          transform: "translateX(-100%) translateY(0%)",
        },
        toDirection: {
          opacity: 1,
          transform: "translateX(0%) translateY(0%)",
        },
      }
    case "right":
      return {
        fromDirection: {
          opacity: 0,
          transform: "translateX(100%) translateY(0%)",
        },
        toDirection: {
          opacity: 1,
          transform: "translateX(0%) translateY(0%)",
        },
      }
    case "up":
      return {
        fromDirection: {
          opacity: 0,
          transform: "translateX(0%) translateY(-100%)",
        },
        toDirection: {
          opacity: 1,
          transform: "translateX(0%) translateY(0%)",
        },
      }
    case "down":
      return {
        fromDirection: {
          opacity: 0,
          transform: "translateX(0%) translateY(100%)",
        },
        toDirection: {
          opacity: 1,
          transform: "translateX(0%) translateY(0%)",
        },
      }

    default:
      return {
        fromDirection: {
          opacity: 0,
          transform: "translateX(0%) translateY(0%)",
        },
        toDirection: {
          opacity: 1,
          transform: "translateX(0%) translateY(0%)",
        },
      }
  }
}

const Transition = props => {
  // console.log("ANIMATION TO", props.direction)

  let { fromDirection } = getInAnimation(props.direction)

  const [isDone, setIsDone] = useState(false)

  const [animatedStyle, setAnimatedStyle] = useSpring(
    () => ({
      from: fromDirection,
      onStart: () => {
        console.log("📝🚀 Transition Started")
        setIsDone(false)
      },
      onRest: () => {
        console.log("📝🚀 Transition Ended")
        setIsDone(true)
      },
      config: { mass: 1, tension: 280, friction: 60 },
    }),
    []
  )

  useEffect(() => {
    let { fromDirection, toDirection } = getInAnimation(props.direction)
    // console.log("ANIMATION TO2", props.direction)
    if (props.autoIn && !props.shouldExit) {
      // In
      setAnimatedStyle.start(toDirection)
    } else {
      // Exit
      setAnimatedStyle.start(fromDirection)
    }
    return () => {
      setAnimatedStyle.delete()
    }
  }, [props.autoIn, props.direction, props.shouldExit, setAnimatedStyle])

  return (
    <animated.div
      className="animatedDivTransition"
      style={!isDone ? animatedStyle : {}}
    >
      {props.children}
    </animated.div>
  )
}

Transition.propTypes = {
  children: PropTypes.node,
}

Transition.defaultProps = {
  children: false,
}

export default Transition
