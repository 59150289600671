import PropTypes from "prop-types"
import React from "react"
import { Box, Flex } from "@chakra-ui/react"

const IntroPageWrapper = props => {
  return (
    <Flex
      pt="140px"
      height="100%"
      minHeight="100vh"
      direction={["column-reverse", "column-reverse", "row"]}
      justify="flex-end"
      className="pointer-events-auto"
      overflowX="hidden"
    >
      {/* ... Pagination Spacing */}
      <Box w="68px"></Box>
      {/* ContentArea */}
      <Flex
        w={["100%", "100%", "50%"]}
        justify="center"
        align={props.isSlider ? "flex-start" : "center"}
        p={["8px", "16px"]}
        mb="32px"
      >
        {/* Left Side */}
        {props.children}
      </Flex>
      <Flex
        w={["100%", "100%", "50%"]}
        justify="center"
        align={[
          "flex-start",
          "flex-start",
          "flex-start",
          "flex-start",
          "center",
        ]}
        padding="16px"
      >
        {/* Right Side */}
        {props.rightContent}
      </Flex>
    </Flex>
  )
}

IntroPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default IntroPageWrapper
